import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import ReactDOM from "react-dom";
import {
  Routes,
  Route,
  Outlet,
  Link,
  BrowserRouter,
  HashRouter
} from "react-router-dom";
import logo from "./logo.svg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import face_content_landing from "./assets/face-content.png";
import face_content_mobile from "./assets/face-content-mobile.png";
import face_cover_desktop from "./assets/face-cover-desktop.mp4";
import face_cover_mobile from "./assets/face-cover-mobile.mp4";

import video_cover_landing from "./assets/banner-vid.mp4";
import mobile_cover_landing from "./assets/Skincell_Mobile_Whole.mp4";
import content_image from "./assets/img-content.jpg";
import mobile_content_image from "./assets/mob-content-vid.mp4";

import image_content_landing from "./assets/img-content.jpg";
import video_content_landing from "./assets/content-vid.mp4";
import image_logo_white from "./assets/logo-white.png";

import image_hamburger_menu from "./assets/hamburger.png";
import image_hamburger_menu_blue from "./assets/hamburger-menu-blue.png";

import facebook_logo from "./assets/facebook_logo.png";
import instagram_logo from "./assets/instagram_logo.png";

import body_content_mobile from "./assets/body_cover_mobile.png";
import body_content_landing from "./assets/body-content.jpg";
import body_cover_desktop from "./assets/body-content-desktop.mp4";
import body_cover_mobile from "./assets/body-content-mobile.mp4";

import laser_content_landing from "./assets/laser-content.jpg";
import laser_content_mobile from "./assets/laser-content-mobile.png";
import laser_cover_desktop from "./assets/laser-content-desktop.mp4";
import laser_cover_mobile from "./assets/laser-content-mobile.mp4";

import premium_cover_desktop from "./assets/premium_content_desktop.jpg";
import premium_cover_mobile from "./assets/premium_cover_mobile.png";
import premium_content_mobile from "./assets/premium-content-mobile.mp4";
import premium_content_desktop from "./assets/premium-content-desktop.mp4";

import review_content_mobile from "./assets/review_content_mobile.png";
import review_cover_desktop from "./assets/review-cover-desktop.jpeg";
import review_cover_mobile from "./assets/review_cover_mobile.png";
import review_carousel_image_1 from "./assets/review-carousel/review_1.png";
import review_carousel_image_2 from "./assets/review-carousel/review_2.png";
import review_carousel_image_3 from "./assets/review-carousel/review_3.png";
import review_carousel_image_4 from "./assets/review-carousel/review_4.png";

import review_carousel_image_5 from "./assets/review-carousel/review_5.png";
import review_carousel_image_6 from "./assets/review-carousel/review_6.png";
import review_carousel_image_7 from "./assets/review-carousel/review_7.png";
import review_carousel_image_8 from "./assets/review-carousel/review_8.png";
import review_carousel_image_9 from "./assets/review-carousel/review_9.png";
import review_carousel_image_10 from "./assets/review-carousel/review_10.png";
import review_carousel_image_11 from "./assets/review-carousel/review_11.png";
import review_carousel_image_12 from "./assets/review-carousel/review_12.png";
import review_carousel_image_13 from "./assets/review-carousel/review_13.png";

import clinic_content_mobile from "./assets/clinic_content_mobile.png";
import clinic_content_landing from "./assets/clinic_content_landing.jpg";
import clinic_cover_desktop from "./assets/clinic_cover_desktop.jpg";
import clinic_cover_mobile from "./assets/clinic_cover_mobile.jpg";

import clinic_content_inside_image from "./assets/clinic_content_inside_image.png";
import review_content_inside_image_mobile from "./assets/review_content_inside_image_mobile.png";
import review_content_inside_image from "./assets/review_content_inside_image.png";

import arrow_svg from "./assets/arrow_down-01.png";
import styled from "styled-components";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const save_array = [];

var home_link = "/";
var menu_link = "/menu";
var face_link = "/face";
var body_link = "/body";
var laser_link = "/laser";
var premium_link = "/premium";
var reviews_link = "/reviews";
var clinics_link = "/clinics";

function App() {
  const inputRef = useRef(null);
  const [home_page, setHomePageContent] = useState(true);
  const [menu_page, setMenuPageContent] = useState(false);
  const [face_page, setFacePageContent] = useState(false);
  const [body_page, setBodyPageContent] = useState(false);
  const [laser_page, setLaserPageContent] = useState(false);
  const [premium_page, setPremiumPageContent] = useState(false);
  const [reviews_page, setReviewsPageContent] = useState(false);
  const [about_page, setAboutPageContent] = useState(false);
  const [clinics_page, setClinicPageContent] = useState(false);

  const [arrow_flag, setArrow_flag] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)"
  });

  const linkStyle = {
    textDecoration: "none",
    color: "blue"
  };

  var page_capture = "";

  const nav_function = (nav) => {
    save_array.push(nav);

    /*  console.log(save_array);
     */
    var initial_save_array = save_array.filter(function (item) {
      return item !== "menu";
    });

    var final_save_array = initial_save_array.filter(function (item) {
      return item !== "close_menu";
    });
    var count_array = parseInt(final_save_array.length - 1);

    window.scrollTo({ top: 0, behavior: "smooth" });

    if (nav == "home") {
      setMenuPageContent(false);
      setHomePageContent(true);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "menu") {
      setMenuPageContent(true);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "face") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(true);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "body") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(true);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "laser") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(true);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "premium") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(true);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "reviews") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(true);
      setAboutPageContent(false);
      setClinicPageContent(false);
    } else if (nav == "about") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(true);
      setClinicPageContent(false);
    } else if (nav == "clinics") {
      setMenuPageContent(false);
      setHomePageContent(false);
      setFacePageContent(false);
      setBodyPageContent(false);
      setLaserPageContent(false);
      setPremiumPageContent(false);
      setReviewsPageContent(false);
      setAboutPageContent(false);
      setClinicPageContent(true);
    }

    if (nav == "close_menu") {
      var detect_last_open_page = final_save_array[count_array];
      /* console.log(detect_last_open_page); */
      if (detect_last_open_page == undefined || detect_last_open_page == null) {
        setMenuPageContent(false);
        setHomePageContent(true);
      }

      if (detect_last_open_page == "home") {
        setMenuPageContent(false);
        setHomePageContent(true);
        setFacePageContent(false);
        setBodyPageContent(false);
        setLaserPageContent(false);
        setPremiumPageContent(false);
        setReviewsPageContent(false);
        setAboutPageContent(false);
      } else if (detect_last_open_page == "face") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(true);
        setBodyPageContent(false);
        setLaserPageContent(false);
        setPremiumPageContent(false);
        setReviewsPageContent(false);
        setAboutPageContent(false);
        setClinicPageContent(false);
      } else if (detect_last_open_page == "body") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(false);
        setBodyPageContent(true);
        setLaserPageContent(false);
        setPremiumPageContent(false);
        setReviewsPageContent(false);
        setAboutPageContent(false);
        setClinicPageContent(false);
      } else if (detect_last_open_page == "laser") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(false);
        setBodyPageContent(false);
        setLaserPageContent(true);
        setPremiumPageContent(false);
        setReviewsPageContent(false);
        setAboutPageContent(false);
        setClinicPageContent(false);
      } else if (detect_last_open_page == "premium") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(false);
        setBodyPageContent(false);
        setLaserPageContent(false);
        setPremiumPageContent(true);
        setReviewsPageContent(false);
        setAboutPageContent(false);
        setClinicPageContent(false);
      } else if (detect_last_open_page == "reviews") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(false);
        setBodyPageContent(false);
        setLaserPageContent(false);
        setPremiumPageContent(false);
        setReviewsPageContent(true);
        setAboutPageContent(false);
        setClinicPageContent(false);
      } else if (detect_last_open_page == "about") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(false);
        setBodyPageContent(false);
        setLaserPageContent(false);
        setPremiumPageContent(false);
        setReviewsPageContent(false);
        setAboutPageContent(true);
        setClinicPageContent(false);
      } else if (detect_last_open_page == "clinics") {
        setMenuPageContent(false);
        setHomePageContent(false);
        setFacePageContent(false);
        setBodyPageContent(false);
        setLaserPageContent(false);
        setPremiumPageContent(false);
        setReviewsPageContent(false);
        setAboutPageContent(false);
        setClinicPageContent(true);
      }
      //setMenuPageContent(false);
    }
  };

  const content_landing_page = () => {
    return (
      <div className="main-content-container main-background-color-white">
        {isDesktopOrLaptop && (
          <div className="main-content-container">
            <div className="inside-content-container-1">
              <div className="inside-content-wrapper">
                <img
                  src={image_content_landing}
                  alt="image_content_landing"
                  className="img-content-cover"
                />

                <div className="label-content-container">
                  BEAUTY
                  <br />
                  IS POWER
                </div>
              </div>
            </div>
            <div className="inside-content-container-2">
              <div className="content">
                SKINCELL is a top dermatology, laser, and skin care clinic
                located in the Philippines. The clinic specializes in advanced
                aesthetic treatments.
                <br />
                <br />
                Our clinics are located in Manila, Makati, and Bonifacio Global
                City; with two mall branches located at Venice Grand Canal Mall
                and Ayala Feliz. <br />
                <br />
                Led by founder Clarissa V. Cellona M.D., our PDS Board Certified
                Doctors are recognized and respected in the medical community
                for their clinical and progressive approach as well as track
                record in offering safe and effective solutions to every skin
                concern. <br />
                <br />
                We also have an FDA-licensed pharmacy managed by a full-time
                pharmacist, to help monitor the safety and efficacy of our
                skincare products and to oversee patient compliance.
                <br />
                <br />
                For clinic inquiries, email info@skincell.care
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="main-content-container-mobile-landing">
            <div className="inside-content-container-1">
              <div className="inside-content-wrapper">
                <img
                  src={image_content_landing}
                  alt="image_content_landing"
                  className="img-content-cover"
                />

                <div className="label-content-container">
                  BEAUTY
                  <br />
                  IS POWER
                </div>
              </div>
            </div>
            <div className="inside-content-container-2">
              <div className="content">
                SKINCELL is a top dermatology, laser, and skin care clinic
                located in the Philippines. The clinic specializes in advanced
                aesthetic treatments.
                <br />
                <br />
                Our clinics are located in Manila, Makati, and Bonifacio Global
                City; with two mall branches located at Venice Grand Canal Mall
                and Ayala Feliz. <br />
                <br />
                Led by founder Clarissa V. Cellona M.D., our PDS Board Certified
                Doctors are recognized and respected in the medical community
                for their clinical and progressive approach as well as track
                record in offering safe and effective solutions to every skin
                concern. <br />
                <br />
                We also have an FDA-licensed pharmacy managed by a full-time
                pharmacist, to help monitor the safety and efficacy of our
                skincare products and to oversee patient compliance.
                <br />
                <br />
                For clinic inquiries, email info@skincell.care
              </div>
            </div>
            <div className="content-white-space" />
          </div>
        )}
      </div>
    );
  };

  const content_face_page = () => {
    return (
      <div className="main-content-container main-background-color-white">
        {isDesktopOrLaptop && (
          <div className="main-content-container center-align">
            <div className="main-inside-content-container">
              <div className="inside-wrapper-title-content">FACE</div>
              <div className="inside-div-content-body">
                We offer an impressive array of luxurious facial treatments
                designed to complement our patient’s regular skincare routine.
                Our facials feature a multi-step, multi-purpose treatment
                program that includes cleansing, extraction, exfoliation,
                oxygenation, face mask, and special creams. Meanwhile, our most
                advanced facials include laser and tightening treatments.
              </div>
              <div className="inside-div-price-body center-align">
                <div className="inside-div-price-1">
                  <div className="content-div-price-face flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">FAMOUS FACIAL</div>
                      <div className="content-label-text">
                        Cleansing.
                        <br /> Extraction.
                        <br />
                        Diamond Peel.
                        <br />
                        Oxygen with Tea Tree Oil. <br />
                        Moisturizing. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align">
                        P4,500
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-face flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">FOREVER FACIAL</div>
                      <div className="content-label-text">
                        Cleansing.
                        <br /> Extraction.
                        <br />
                        Diamond Peel.
                        <br />
                        Oxygen with Tea Tree Oil. <br />
                        Coldpeel Laser. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align text-align-right">
                        P8,000
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inside-div-price-1">
                  <div className="content-div-price-face flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">ACNECLEAR</div>
                      <div className="content-label-text">
                        Cleansing.
                        <br />
                        Extraction.
                        <br />
                        AntiBacterial Injection.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align text-align-right">
                        P5,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-face flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">ULTIMATE FACIAL</div>
                      <div className="content-label-text">
                        Cleansing.
                        <br />
                        Extraction.
                        <br />
                        Diamond Peel.
                        <br />
                        Oxygen Facial.
                        <br />
                        Lifting Treatment.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align text-align-right">
                        P10,000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="main-content-container-mobile-face">
            <div className="inside-wrapper-title-content">FACE</div>

            <div className="inside-div-content-mobile">
              We offer an impressive array of luxurious facial treatments
              designed to complement our patient’s regular skincare routine. Our
              facials feature a multi-step, multi-purpose treatment program that
              includes cleansing, extraction, exfoliation, oxygenation, face
              mask, and special creams. Meanwhile, our most advanced facials
              include laser and tightening treatments.
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">FAMOUS FACIAL</div>
                <div className="content-label-text">
                  Cleansing.
                  <br /> Extraction.
                  <br /> Diamond Peel. <br />
                  Oxygen with Tea Tree Oil. <br />
                  Moisturizing.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">P4,500</div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">FOREVER FACIAL</div>
                <div className="content-label-text">
                  Cleansing.
                  <br /> Extraction.
                  <br /> Diamond Peel. <br />
                  Oxygen with Tea Tree Oil. <br />
                  Coldpeel Laser.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">P8,000</div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">ACNE CLEAR</div>
                <div className="content-label-text">
                  Cleansing.
                  <br /> Extraction.
                  <br /> AntiBacterial Injection.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">P5,000</div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">ULTIMATE FACIAL</div>
                <div className="content-label-text">
                  Cleansing.
                  <br /> Extraction.
                  <br /> Diamond Peel.
                  <br /> Oxygen Facial.
                  <br /> Lifting Treatment.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P10,000
                </div>
              </div>
            </div>
            <div className="content-white-space" />
          </div>
        )}
      </div>
    );
  };

  const content_body_page = () => {
    return (
      <div className="main-content-container main-background-color-white">
        {isDesktopOrLaptop && (
          <div className="main-content-container center-align">
            <div className="main-inside-content-container">
              <div className="inside-wrapper-title-content">HAIR AND BODY</div>
              <div className="inside-div-content-body">
                Our full range of non-surgical to minimally invasive hair and
                body treatments are ideal for targeting specific areas in need
                of improvement and results. We use the same patient customized
                approach with our high-end performance hair and body machines,
                so you can confidently invest in your best self, knowing you
                will get the results you need.
              </div>
              <div className="inside-div-price-body center-align">
                <div className="inside-div-price-1">
                  <div className="content-div-price flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">COOLWAVE</div>
                      <div className="content-label-text">
                        Abdomen.
                        <br /> Thighs.
                        <br />
                        Butt.
                        <br />
                        Arms. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align">
                        P10,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price flex-start-center-align magin-top">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">INJECTABLES</div>
                      <div className="content-label-text">
                        Forehead.
                        <br /> Crow’s Feet.
                        <br />
                        Frown Lines.
                        <br />
                        Underarms. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align text-align-right">
                        CALL FOR PRICING
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inside-div-price-1">
                  <div className="content-div-price flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text">NADPLUS</div>
                      <div className="content-label-text">Hair & Scalp.</div>
                    </div>
                    <div className="content-inside-container-new">
                      <div className="content-header-text flex-end-align text-align-right">
                        P25,0000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className="main-content-container-mobile-body">
            <div className="inside-wrapper-title-content">HAIR AND BODY</div>

            <div className="inside-div-content-mobile">
              Our full range of non-surgical to minimally invasive hair and body
              treatments are ideal for targeting specific areas in need of
              improvement and results. We use the same patient customized
              approach with our high-end performance hair and body machines, so
              you can confidently invest in your best self, knowing you will get
              the results you need.
            </div>
            <div className="flex-start-center-align content-inside-container-mobile-body">
              <div className="content-inside-container">
                <div className="content-header-text">COOLWAVE</div>
                <div className="content-label-text">
                  Abdomen.
                  <br /> Thighs.
                  <br />
                  Butt.
                  <br />
                  Arms. <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P10,000
                </div>
              </div>
            </div>

            <div className="flex-start-center-align content-inside-container-mobile-body">
              <div className="content-inside-container">
                <div className="content-header-text">NADPLUS</div>
                <div className="content-label-text">Hair and Scalp.</div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align ">
                  P25,000
                </div>
              </div>
            </div>

            <div className="flex-start-center-align content-inside-container-mobile-body">
              <div className="content-inside-container">
                <div className="content-header-text">INJECTABLES</div>
                <div className="content-label-text flex-start-align">
                  Forehead.
                  <br /> Crow’s Feet.
                  <br /> Frown Lines. <br />
                  Underarms. <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align text-align-right">
                  CALL FOR PRICING
                </div>
              </div>
            </div>

            <div className="content-white-space" />
          </div>
        )}
      </div>
    );
  };

  const content_laser_page = () => {
    return (
      <div className="main-content-container main-background-color-white">
        {isDesktopOrLaptop && (
          <div className="main-content-container center-align">
            <div className="main-inside-content-container">
              <div className="inside-wrapper-title-content">LASER</div>
              <div className="inside-div-content-laser">
                Our most popular lasers for skin lightening, acne scars, skin
                rejuvenation and brightening utilizes the most advanced
                aesthetic innovation to ensure patients get the right treatment
                outcome with very little to no downtime.
              </div>
              <div className="inside-div-price-body center-align">
                <div className="inside-div-price-1">
                  <div className="content-div-price-laser flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser">BODYBRITE</div>
                      <div className="content-label-text-laser">
                        Underarms.
                        <br /> Elbows.
                        <br />
                        Knees.
                        <br />
                        Buttocks. <br />
                        Inner Thighs. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser flex-end-align">
                        P6,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-laser flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser">
                        FRX (NON-ABLATIVE){" "}
                      </div>
                      <div className="content-label-text-laser">
                        Acne Scar.
                        <br /> Pore Refinement.
                        <br />
                        Stretchmarks.
                        <br />
                        Sun Damage. <br />
                        Pigmentation. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser flex-end-align text-align-right">
                        P17,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-laser flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser">LASERHAIR</div>
                      <div className="content-label-text-laser">
                        Facial.
                        <br /> Underarms.
                        <br />
                        Legs.
                        <br />
                        Bikini. <br />
                      </div>
                    </div>

                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser flex-end-align text-align-right">
                        CALL FOR PRICING
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inside-div-price-1">
                  <div className="content-div-price-laser flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser">LASERGEN </div>
                      <div className="content-label-text-laser">
                        Rosacea.
                        <br />
                        Sun Damage.
                        <br />
                        Dryness & Irritation.
                        <br />
                        Improve Complexion.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser flex-end-align text-align-right">
                        P10,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-laser flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser">
                        4DLASERLIFT
                      </div>
                      <div className="content-label-text-laser">
                        Tightening.
                        <br />
                        Volumization.
                        <br />
                        Rejuvenation.
                        <br />
                        Laser Peeling.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser flex-end-align text-align-right">
                        P35,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-laser flex-start-center-align">
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser">LASERVEIN</div>
                      <div className="content-label-text-laser">
                        Face.
                        <br />
                        Thighs.
                        <br />
                        Legs.
                        <br />
                        Feet.
                        <br />
                        Knee Area.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new">
                      <div className="content-header-text-laser flex-end-align text-align-right">
                        P25,000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="main-content-container-mobile-laser">
            <div className="inside-wrapper-title-content">LASER</div>
            <div className="inside-div-content-mobile">
              Our most popular lasers for skin lightening, acne scars, skin
              rejuvenation and brightening utilizes the most advanced aesthetic
              innovation to ensure patients get the right treatment outcome with
              very little to no downtime.
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">BODYBRITE</div>
                <div className="content-label-text">
                  Underarms.
                  <br /> Elbows.
                  <br />
                  Knees.
                  <br />
                  Buttocks. <br />
                  Inner Thighs. <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">P6,000</div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">4DLASERLIFT</div>
                <div className="content-label-text">
                  Tightening.
                  <br /> Volumization.
                  <br /> Rejuvenation. <br />
                  Laser Peeling. <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P35,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">FRX (NON-ABLATIVE)</div>
                <div className="content-label-text">
                  Acne Scar.
                  <br /> Pore Refinement.
                  <br /> Stretchmarks.
                  <br /> Sun Damage.
                  <br /> Pigmentation.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P17,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">LASERGEN</div>
                <div className="content-label-text">
                  Rosacea.
                  <br /> Sun Damage.
                  <br /> Diamond Peel.
                  <br /> Dryness & Irritation.
                  <br /> Improve Complexion.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P10,000
                </div>
              </div>
            </div>

            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">LASERVEIN</div>
                <div className="content-label-text">
                  Face.
                  <br /> Thighs.
                  <br /> Legs.
                  <br /> Feet.
                  <br /> Knee Area.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P25,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">LASERHAIR</div>
                <div className="content-label-text">
                  Facial.
                  <br /> Underarms.
                  <br /> Legs.
                  <br /> Bikini.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align text-align-right">
                  CALL FOR PRICING
                </div>
              </div>
            </div>
            <div className="content-white-space" />
          </div>
        )}
      </div>
    );
  };

  const content_premium_page = () => {
    return (
      <div className="main-content-container main-background-color-white">
        {isDesktopOrLaptop && (
          <div className="main-content-container center-align">
            <div className="main-inside-content-container">
              <div className="inside-wrapper-title-content">PREMIUM</div>
              <div className="inside-div-content-premium">
                Slow the process of aging with our most advanced premium line of
                treatments targeting concerns such as fine lines, wrinkles,
                sagging and loose skin. Our in-clinic treatments feature
                ultrasound technology, radiofrequency devices, injectables,
                microneedling, laser and other non-surgical lifting techniques.
              </div>
              <div className="inside-div-price-body center-align">
                <div className="inside-div-price-1">
                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">
                        INSTALIFT
                      </div>
                      <div className="content-label-text-premium">
                        Firming & Tightening.
                        <br /> Collagen & Elastin Production.
                        <br />
                        Improves Blemishes.
                      </div>
                    </div>

                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align">
                        P30,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">SOFWAVE</div>
                      <div className="content-label-text-premium">
                        Fine Lines & Wrinkles.
                        <br /> Collagen Production.
                        <br />
                        Eyebrows, Neck & Submental.
                      </div>
                    </div>

                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align text-align-right">
                        P65,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">
                        THERMAGE FLX
                      </div>
                      <div className="content-label-text-premium">
                        Skin Tightening.
                        <br /> Contouring.
                        <br />
                        Non-invasive Lift.
                      </div>
                    </div>

                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align text-align-right">
                        P125,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">VSX</div>
                      <div className="content-label-text-premium">
                        Age Defying.
                        <br />
                        Skin Tightening.
                        <br />
                        Glass Skin.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align text-align-right">
                        CALL FOR PRICING
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inside-div-price-1">
                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">RFX</div>
                      <div className="content-label-text-premium">
                        Firming & Tightening.
                        <br />
                        Collagen & Elastin Production.
                        <br />
                        Improves Blemishes.
                      </div>
                    </div>
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align text-align-right">
                        P60,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">ULTHERA</div>
                      <div className="content-label-text-premium">
                        Skin Tightening.
                        <br />
                        Non-invasive Lift.
                        <br />
                        Collagen Production.
                      </div>
                    </div>
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align text-align-right">
                        P120,000
                      </div>
                    </div>
                  </div>

                  <div className="content-div-price-premium flex-start-center-align">
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium">TMP</div>
                      <div className="content-label-text-premium">
                        Firming & Tightening.
                        <br />
                        Collagen & Elastin Production.
                        <br />
                        Improves Blemishes.
                        <br />
                      </div>
                    </div>
                    <div className="content-inside-container-new-premium">
                      <div className="content-header-text-premium flex-end-align text-align-right">
                        P250,000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="main-content-container-mobile-premium">
            <div className="inside-wrapper-title-content">PREMIUM</div>
            <div className="inside-div-content-mobile">
              Slow the process of aging with our most advanced premium line of
              treatments targeting concerns such as fine lines, wrinkles,
              sagging and loose skin. Our in-clinic treatments feature
              ultrasound technology, radiofrequency devices, injectables,
              microneedling, laser and other non-surgical lifting techniques.
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">ULTHERA</div>
                <div className="content-label-text">
                  Skin Tightening.
                  <br /> Non-invasive Lift.
                  <br />
                  Collagen Production.
                  <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P120,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">SOFWAVE</div>
                <div className="content-label-text">
                  Fine Lines & Wrinkles.
                  <br /> Collagen Production.
                  <br />
                  Eyebrows, Neck & Submental. <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P65,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">INSTALIFT</div>
                <div className="content-label-text">
                  Firming & Tightening.
                  <br /> Collagen & Elastin Production.
                  <br /> Improves Blemishes. <br />
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P30,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">THERMAGE FLX</div>
                <div className="content-label-text">
                  Skin Tightening.
                  <br /> Contouring.
                  <br /> Non-invasive Lift.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P125,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">RFX</div>
                <div className="content-label-text">
                  Firming & Tightening.
                  <br /> Collagen & Elastin Production.
                  <br /> Improves Blemishes.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P60,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">TMP</div>
                <div className="content-label-text">
                  Firming & Tightening.
                  <br /> Collagen & Elastin Production.
                  <br /> Improves Blemishes.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align">
                  P250,000
                </div>
              </div>
            </div>
            <div className="center-align content-inside-container-mobile">
              <div className="content-inside-container">
                <div className="content-header-text">VSX</div>
                <div className="content-label-text">
                  Age Defying.
                  <br /> Skin Tightening.
                  <br /> Glass Skin.
                </div>
              </div>
              <div className="content-inside-container">
                <div className="content-header-text flex-end-align text-align-right">
                  CALL FOR PRICING
                </div>
              </div>
            </div>
            <div className="content-white-space" />
          </div>
        )}
      </div>
    );
  };

  const content_review_page = () => {
    return (
      <div className="main-content-container-review main-background-color-secondary flex-start-center-align">
        {isDesktopOrLaptop && (
          <div className="inside-wrapper-content-3-review">
            <Carousel
              emulateTouch={true}
              showArrows={false}
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              dynamicHeight={true}
              infiniteLoop={true}
              showIndicators={false}
            >
              <div>
                <img src={review_carousel_image_1} />
              </div>
              <div>
                <img src={review_carousel_image_2} />
              </div>
              <div>
                <img src={review_carousel_image_3} />
              </div>
              <div>
                <img src={review_carousel_image_4} />
              </div>
              <div>
                <img src={review_carousel_image_5} />
              </div>
              <div>
                <img src={review_carousel_image_6} />
              </div>
              <div>
                <img src={review_carousel_image_7} />
              </div>
              <div>
                <img src={review_carousel_image_8} />
              </div>
              <div>
                <img src={review_carousel_image_9} />
              </div>
              <div>
                <img src={review_carousel_image_10} />
              </div>
              <div>
                <img src={review_carousel_image_11} />
              </div>
              <div>
                <img src={review_carousel_image_12} />
              </div>
              <div>
                <img src={review_carousel_image_13} />
              </div>
            </Carousel>
          </div>
        )}

        {isMobile && (
          <div className="inside-wrapper-content-3-review ">
            <Carousel
              showArrows={false}
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              dynamicHeight={true}
              infiniteLoop={true}
              showIndicators={false}
            >
              <div>
                <img src={review_carousel_image_1} />
              </div>
              <div>
                <img src={review_carousel_image_2} />
              </div>
              <div>
                <img src={review_carousel_image_3} />
              </div>
              <div>
                <img src={review_carousel_image_4} />
              </div>
              <div>
                <img src={review_carousel_image_5} />
              </div>
              <div>
                <img src={review_carousel_image_6} />
              </div>
              <div>
                <img src={review_carousel_image_7} />
              </div>
              <div>
                <img src={review_carousel_image_8} />
              </div>
              <div>
                <img src={review_carousel_image_9} />
              </div>
              <div>
                <img src={review_carousel_image_10} />
              </div>
              <div>
                <img src={review_carousel_image_11} />
              </div>
              <div>
                <img src={review_carousel_image_12} />
              </div>
              <div>
                <img src={review_carousel_image_13} />
              </div>
            </Carousel>
          </div>
        )}
      </div>
    );
  };

  const content_clinics_page = () => {
    return (
      <div className="main-content-container main-background-color-white center-align">
        {isDesktopOrLaptop && (
          <div className="inside-wrapper-content">
            <div className="inside-content-container-5 center-align">
              <div className="inside-div-container-1-clinics-title header-text flex-start-align">
                CLINICS
              </div>
            </div>
            <div className="inside-content-container-8 center-align">
              <div className="inside-div-container-1-clinics center-align flex-all-start-align">
                <img
                  src={clinic_content_inside_image}
                  alt="clinic_content_inside_image"
                  className="clinic_content_inside_image"
                />
              </div>
              <div className="inside-div-container-1-clinics">
                <div className="clinic-container">
                  <div className="clinic-text-header">MANILA</div>
                  <div className="clinic-content-header">
                    M/L New Bocobo Center, J. Bocobo corner Padre Faura St.,
                    Ermita, Manila
                    <br />
                    Mobile +63 (917) 832 2445 | Landline +63 (2) 8526 7546
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">MAKATI</div>
                  <div className="clinic-content-header">
                    2/F San Antonio Plaza, McKinley Road, Forbes Park, Makati
                    City
                    <br />
                    Mobile +63 (917) 799 1412 | Landline +63 (2) 8777 2355
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">
                    BGC (Clinical Practice of Clarissa V. Cellona, MD, FPDS)
                  </div>
                  <div className="clinic-content-header">
                    Rm 818 Medical Arts Building, St. Luke's Medical Center
                    Global City
                    <br />
                    Mobile +63 (917) 599 7620 | Landline +63 (2) 8789 7700
                  </div>
                </div>

                {/*   <div className="clinic-container">
                  <div className="clinic-text-header">TAGUIG</div>
                  <div className="clinic-content-header">
                    2/F Venice Grand Canal Mall, Upper McKinley Road, Taguig
                    City
                    <br />
                    Mobile +63 (915) 603 4769
                     Mobile +63 (915) 603 4769 | Landline +63 (2) 7625 4552 
                  </div>
                </div> */}
                <div className="clinic-container">
                  <div className="clinic-text-header">MCKINLEY HILL</div>
                  <div className="clinic-content-header">
                    3/F San Marco Wing Venice Grand Canal Mall Upper McKinley
                    Hill Taguig City PH
                    <br />M +63 (915) 6034769
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">PASIG</div>
                  <div className="clinic-content-header">
                    3/L Ayala Malls Feliz, Marcos Highway, Dela Paz, Pasig City
                    <br />
                    Mobile +63 (906) 084 7629 | Landline +63 (2) 7759 6868
                  </div>
                </div>
              </div>
            </div>
            {/* 
            <div className="inside-content-container-6 center-align">
              <div className="inside-div-container-1 content-label-text-1">
                SKINCELL Advanced Aesthetic Clinics are easily located in key
                areas within Metro Manila.
                <br />
                <br />
                <br />
                Enjoy our safe, non-invasive, and clinically tested procedures
                at any of our locations in Manila, Makati, Bonifacio Global
                Center, or at Venice Grand Canal Mall and Ayala Feliz.
                <br />
                <br />
                <br />
                Contact any of our clinic numbers located in the directory
                below.
              </div>
            </div>
            <div className="inside-content-container-7 center-align">
              <div className="inside-div-container-1 center-align">
                <div className="clinic-container">
                  <div className="clinic-text-header">MAKATI</div>
                  <div className="clinic-content-header">
                    2/F San Antonio Plaza,
                    <br />
                    McKinley Road, Forbes
                    <br />
                    Park, Makati City PH
                    <br />
                    T +63 (02) 87772355
                    <br />M +63 (917) 7991412
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">MCKINLEY HILL</div>
                  <div className="clinic-content-header">
                    2/F Venice Grand Canal Mall,
                    <br />
                    Upper McKinley Road,
                    <br />
                    Taguig City PH
                    <br />T +63 (02) 76254552
                    <br />M +63 (915) 6034769
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">MANILA</div>
                  <div className="clinic-content-header">
                    M/L New Bocobo Center
                    <br />
                    J. Bocobo corner Padre
                    <br />
                    PFaura St. Ermita, Manila PH
                    <br />T +63 (02) 85267546
                    <br />M +63 (917) 8322445
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">BGC</div>
                  <div className="clinic-content-header">
                    CELLONA SKIN CLINIC
                    <br />
                    Room 818 Medical Arts
                    <br />
                    Bldg.St. Lukes Medical Center BGC
                    <br />, Taguig PH
                    <br />T +63 (02) 87897700
                    <br />M +63 (917) 5997620
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        )}

        {isMobile && (
          <div className="inside-wrapper-content-clinic">
            <div className="inside-content-container-5 center-align">
              <div className="inside-div-container-1 header-text flex-start-align">
                CLINICS
              </div>
            </div>
            {/* <div className="inside-content-container-6 center-align">
              <div className="inside-div-container-1 content-label-text-1">
                SKINCELL Advanced Aesthetic Clinics are easily located in key
                areas within Metro Manila.
                <br />
                <br />
                <br />
                Enjoy our safe, non-invasive, and clinically tested procedures
                at any of our locations in Manila, Makati, Bonifacio Global
                Center, or at Venice Grand Canal Mall and Ayala Feliz.
                <br />
                <br />
                <br />
                Contact any of our clinic numbers located in the directory
                below.
              </div>
            </div> */}
            <div className="inside-content-container-7 center-align">
              <div className="inside-div-container-1-mobile">
                <div className="clinic-container">
                  <div className="clinic-text-header">MANILA</div>
                  <div className="clinic-content-header">
                    M/L New Bocobo Center, J. Bocobo corner Padre Faura St.,
                    Ermita, Manila
                    <br />
                    Mobile +63 (917) 832 2445
                    <br />
                    Landline +63 (2) 8526 7546
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">MAKATI</div>
                  <div className="clinic-content-header">
                    2/F San Antonio Plaza, McKinley Road, Forbes Park, Makati
                    City
                    <br />
                    Mobile +63 (917) 799 1412
                    <br />
                    Landline +63 (2) 8777 2355
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">
                    BGC (Clinical Practice of Clarissa V. Cellona, MD, FPDS)
                  </div>
                  <div className="clinic-content-header">
                    Rm 818 Medical Arts Building, St. Luke's Medical Center
                    Global City
                    <br />
                    Mobile +63 (917) 599 7620
                    <br />
                    Landline +63 (2) 8789 7700
                  </div>
                </div>
                {/*  <div className="clinic-container">
                  <div className="clinic-text-header">TAGUIG</div>
                  <div className="clinic-content-header">
                    2/F Venice Grand Canal Mall, Upper McKinley Road, Taguig
                    City
                    <br />
                    Mobile +63 (915) 6034769
                       <br />
                    Landline +63 (2) 7625 4552 
                  </div>
                </div> */}
                <div className="clinic-container">
                  <div className="clinic-text-header">MCKINLEY HILL</div>
                  <div className="clinic-content-header">
                    3/F San Marco Wing Venice Grand Canal Mall Upper McKinley
                    Hill Taguig City PH
                    <br />M +63 (915) 6034769
                  </div>
                </div>
                <div className="clinic-container">
                  <div className="clinic-text-header">PASIG</div>
                  <div className="clinic-content-header">
                    3/L Ayala Malls Feliz, Marcos Highway, Dela Paz, Pasig City
                    <br />
                    Mobile +63 (906) 084 7629
                    <br />
                    Landline +63 (2) 7759 6868
                  </div>
                </div>

                {/*  <div className="clinic-container-image center-align">
                  <img
                    src={clinic_content_inside_image}
                    alt="clinic_content_inside_image"
                    className="clinic_content_inside_image"
                  />
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const content_review__page_two = () => {
    return (
      <div className="main-content-container main-background-color-white center-align">
        {isDesktopOrLaptop && (
          <div className="inside-wrapper-content">
            <div className="inside-content-container-5 center-align">
              <div className="inside-div-container-1-reviews-title header-text flex-start-align">
                REVIEWS
              </div>
            </div>
            <div className="inside-content-container-8 center-align">
              <div className="inside-div-container-1-reviews">
                <img
                  src={review_content_inside_image}
                  alt="review_content_inside_image"
                  className="review_content_inside_image"
                />
              </div>
              <div className="inside-div-container-2-reviews reviews-content-text">
                For over 20 years, SKINCELL Founder and Lead Dermatologist Dr.
                Clarissa V. Cellona has been at the forefront of advanced
                aesthetic treatments in the Philippines.
                <br />
                <br />
                Dr. Issa graduated via the INTARMED program of the University of
                the Philippines College of Medicine and trained in Dermatology
                at the UP Philippine General Hospital. She then completed her
                fellowships under the mentorship of renowned American
                Dermatologist Dr. Howard Maibach at the University of California
                San Francisco (UCSF).
                <br />
                <br />
                SKINCELL was founded on Dr. Issa’s vision to provide safe and
                effective skin care for her patients using advanced technology.
                <br />
                <br />
                All SKINCELL clinics practice international standards to ensure
                world-class care, delivering safe and effective treatments and
                skincare programs at par with some of the world’s best
                dermatology clinics.
                <br />
                <br />
                Dr. Issa and her group of Board-Certified Dermatologists are
                members of the Philippine Dermatological Society, the only
                specialty society recognized by the Philippine Medical
                Association (PMA) and the Philippine College of Physicians
                (PCP).
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="inside-wrapper-content-review">
            <div className="inside-content-container-5 center-align">
              <div className="inside-div-container-1 header-text flex-start-align">
                REVIEWS
              </div>
            </div>

            <div className="inside-content-container-reviews-image center-align">
              <div className="inside-background-reviews"></div>
            </div>
            <div className="inside-content-container-6 center-align">
              <div className="inside-div-container-1 content-label-text-1">
                For over 20 years, SKINCELL Founder and Lead Dermatologist Dr.
                Clarissa V. Cellona has been at the forefront of advanced
                aesthetic treatments in the Philippines.
                <br />
                <br />
                Dr. Issa graduated via the INTARMED program of the University of
                the Philippines College of Medicine and trained in Dermatology
                at the UP Philippine General Hospital. She then completed her
                fellowships under the mentorship of renowned American
                Dermatologist Dr. Howard Maibach at the University of California
                San Francisco (UCSF).
                <br />
                <br />
                SKINCELL was founded on Dr. Issa’s vision to provide safe and
                effective skin care for her patients using advanced technology.
                <br />
                <br />
                All SKINCELL clinics practice international standards to ensure
                world-class care, delivering safe and effective treatments and
                skincare programs at par with some of the world’s best
                dermatology clinics.
                <br />
                <br />
                Dr. Issa and her group of Board-Certified Dermatologists are
                members of the Philippine Dermatological Society, the only
                specialty society recognized by the Philippine Medical
                Association (PMA) and the Philippine College of Physicians
                (PCP).
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const ref = useRef();
  const scrollDown = () => {
    /*  var element = ReactDOM.findDOMNode(
      ref["main-div-container"]
    ).getBoundingClientRect();
    var position = ref.current.getBoundingClientRect();

    console.log(element.y); */
    if (isDesktopOrLaptop == true) {
      window.scrollTo({ top: 1500, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 800, behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.onscroll = function () {
      var distanceScrolled = document.documentElement.scrollTop;
      /*  console.log("Scrolled: " + distanceScrolled); */
      if (parseInt(distanceScrolled) > 300) {
        setArrow_flag(false);
      } else {
        setArrow_flag(true);
      }
    };
  }, []);

  const layout = (
    cover_video,
    cover_type,
    content,
    content_video,
    content_type,
    nav,
    cover_video_mobile,
    content_video_mobile,
    link
  ) => {
    return (
      <div ref={ref} className="main-div-container">
        <div className="first-container-cover">
          <div className="hamburger-container">
            {/* <div onClick={() => nav_function("menu")}> */}
            <Link to={link} style={linkStyle}>
              <img
                src={image_hamburger_menu}
                alt="image_hamburger_menu"
                className="image_hamburger_menu"
              />
            </Link>
            {/*    </div> */}
          </div>
          {arrow_flag && (
            <div className="arrow-svg-container" onClick={() => scrollDown()}>
              <img src={arrow_svg} alt="svg" className="arrow-svg" />
            </div>
          )}
          <div className="first-container-video">
            {isDesktopOrLaptop && (
              <div>
                {cover_type == "video" ? (
                  <video
                    src={cover_video}
                    className="video-style"
                    type="video/mp4"
                    autoPlay
                    loop
                    muted
                    playsinline
                    defaultmuted
                    preload="auto"
                  />
                ) : (
                  <img
                    src={cover_video}
                    alt="image_logo_white"
                    className="image_content"
                  />
                )}
              </div>
            )}
            {isMobile && (
              <div>
                {cover_type == "video" ? (
                  <video
                    src={cover_video_mobile}
                    className="video-style"
                    type="video/mp4"
                    autoPlay
                    playsInline
                    muted
                    loop
                  />
                ) : (
                  <img
                    src={cover_video_mobile}
                    alt="image_logo_white"
                    className="image_content"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {isDesktopOrLaptop && <div className="first-container">{content}</div>}
        {isMobile && <div className="first-container-mobile">{content}</div>}
        <div className="first-container-content main-background-color">
          {isDesktopOrLaptop && (
            <div>
              {content_type == "video" ? (
                <video
                  src={content_video}
                  className="video-style"
                  type="video/mp4"
                  autoPlay
                  playsInline
                  muted
                  loop
                />
              ) : (
                <img
                  src={content_video}
                  alt="image_logo_white"
                  className="image_content"
                />
              )}
            </div>
          )}
          {isMobile && (
            <div className="first-container main-background-color">
              {content_type == "video" ? (
                <video
                  src={content_video_mobile}
                  className="video-style"
                  type="video/mp4"
                  autoPlay
                  playsInline
                  muted
                  loop
                />
              ) : (
                <img
                  src={content_video_mobile}
                  alt="image_logo_white"
                  className="image_content"
                />
              )}
            </div>
          )}
        </div>
        <div className="first-container main-background-color center-align">
          {isDesktopOrLaptop && (
            <div className="location-container main-background-color">
              <div className="location-inside-container-1 center-align">
                <div className="menu-inside-container-1">
                  <Link to={face_link} style={linkStyle}>
                    <div
                      className="menu-footer-style"
                      onClick={() => nav_function("face")}
                    >
                      {"FACE"}
                    </div>
                  </Link>
                  <Link to={body_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("body")}
                    >
                      {"BODY"}
                    </div>
                  </Link>
                  <Link to={laser_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("laser")}
                    >
                      {"LASER"}
                    </div>
                  </Link>
                  <Link to={premium_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("premium")}
                    >
                      {"PREMIUM"}
                    </div>
                  </Link>
                  <Link to={reviews_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("reviews")}
                    >
                      {"REVIEWS"}
                    </div>
                  </Link>
                  <Link to={home_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("home")}
                    >
                      {"ABOUT"}
                    </div>
                  </Link>
                  <Link to={clinics_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("clinics")}
                    >
                      {"CLINICS"}
                    </div>
                  </Link>
                </div>
                <div className="menu-inside-container-1"></div>
              </div>
              <div className="location-inside-container-2">
                <div className="logo-container">
                  <div className="line"> </div>
                  <Link to={home_link} style={linkStyle}>
                    <div
                      className="logo-image-container"
                      onClick={() => nav_function("home")}
                    >
                      <img
                        src={image_logo_white}
                        alt="image_logo_white"
                        className="image_logo_white"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className="location-container main-background-color center-align">
              <div className="location-container">
                <div className="location-footer-mobile-container">
                  <Link to={face_link} style={linkStyle}>
                    <div
                      className="menu-footer-style"
                      onClick={() => nav_function("face")}
                    >
                      {"FACE"}
                    </div>
                  </Link>
                  <Link to={body_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align "
                      onClick={() => nav_function("body")}
                    >
                      {"BODY"}
                    </div>
                  </Link>
                  <Link to={laser_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("laser")}
                    >
                      {"LASER"}
                    </div>
                  </Link>
                  <Link to={premium_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("premium")}
                    >
                      {"PREMIUM"}
                    </div>
                  </Link>
                  <Link to={reviews_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("reviews")}
                    >
                      {"REVIEWS"}
                    </div>
                  </Link>
                  <Link to={home_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("home")}
                    >
                      {"ABOUT"}
                    </div>
                  </Link>
                  <Link to={clinics_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("clinics")}
                    >
                      {"CLINICS"}
                    </div>
                  </Link>
                </div>

                <div className="location-mobile-logo-container center-align">
                  <div className="inside-wrapper-content-2">
                    <div className="line"> </div>
                    <Link to={home_link} style={linkStyle}>
                      <div
                        className="logo-image-container"
                        onClick={() => nav_function("home")}
                      >
                        {/*  <img
                        src={image_logo_white}
                        alt="image_logo_white"
                        className="image_logo_white"
                      /> */}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const layout_2 = (
    cover_video,
    cover_type,
    content,
    content_two,
    nav,
    cover_video_mobile,
    link
  ) => {
    return (
      <div className="main-div-container">
        <div className="first-container-cover">
          <div className="hamburger-container">
            <div onClick={() => nav_function("menu")}>
              <Link to={link} style={linkStyle}>
                <img
                  src={image_hamburger_menu}
                  alt="image_hamburger_menu"
                  className="image_hamburger_menu"
                />
              </Link>
            </div>
          </div>
          <div className="first-container-video-1">
            {isDesktopOrLaptop && (
              <div>
                {cover_type == "video" ? (
                  <video
                    src={cover_video}
                    className="video-style"
                    type="video/mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                ) : (
                  <img
                    src={cover_video}
                    alt="image_logo_white"
                    className="image_content"
                  />
                )}
              </div>
            )}
            {isMobile && (
              <div>
                {cover_type == "video" ? (
                  <video
                    src={cover_video_mobile}
                    className="video-style"
                    type="video/mp4"
                    autoPlay
                    playsInline
                    muted
                    loop
                  />
                ) : (
                  <img
                    src={cover_video_mobile}
                    alt="image_logo_white"
                    className="image_content"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {isDesktopOrLaptop && (
          <div className="first-container">{content_two}</div>
        )}
        {isMobile && (
          <div className="first-container-mobile">{content_two}</div>
        )}

        {isDesktopOrLaptop && <div className="first-container">{content}</div>}
        {isMobile && <div className="first-container-mobile">{content}</div>}

        <div className="first-container main-background-color center-align">
          {isDesktopOrLaptop && (
            <div className="location-container main-background-color">
              <div className="location-inside-container-1 center-align">
                <div className="menu-inside-container-1">
                  <Link to={face_link} style={linkStyle}>
                    <div
                      className="menu-footer-style"
                      onClick={() => nav_function("face")}
                    >
                      {"FACE"}
                    </div>
                  </Link>
                  <Link to={body_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("body")}
                    >
                      {"BODY"}
                    </div>
                  </Link>
                  <Link to={laser_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("laser")}
                    >
                      {"LASER"}
                    </div>
                  </Link>
                  <Link to={premium_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("premium")}
                    >
                      {"PREMIUM"}
                    </div>
                  </Link>
                  <Link to={reviews_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("reviews")}
                    >
                      {"REVIEWS"}
                    </div>
                  </Link>
                  <Link to={home_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("home")}
                    >
                      {"ABOUT"}
                    </div>
                  </Link>
                  <Link to={clinics_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("clinics")}
                    >
                      {"CLINICS"}
                    </div>
                  </Link>
                </div>
                <div className="menu-inside-container-1"></div>
              </div>
              <div className="location-inside-container-2">
                <div className="logo-container">
                  <div className="line"> </div>
                  <Link to={home_link} style={linkStyle}>
                    <div
                      className="logo-image-container"
                      onClick={() => nav_function("home")}
                    >
                      <img
                        src={image_logo_white}
                        alt="image_logo_white"
                        className="image_logo_white"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className="location-container main-background-color center-align">
              <div className="location-container">
                <div className="location-footer-mobile-container">
                  <Link to={face_link} style={linkStyle}>
                    <div
                      className="menu-footer-style"
                      onClick={() => nav_function("face")}
                    >
                      {"FACE"}
                    </div>
                  </Link>
                  <Link to={body_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("body")}
                    >
                      {"BODY"}
                    </div>
                  </Link>
                  <Link to={laser_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("laser")}
                    >
                      {"LASER"}
                    </div>
                  </Link>
                  <Link to={premium_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("premium")}
                    >
                      {"PREMIUM"}
                    </div>
                  </Link>
                  <Link to={reviews_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("reviews")}
                    >
                      {"REVIEWS"}
                    </div>
                  </Link>
                  <Link to={home_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("home")}
                    >
                      {"ABOUT"}
                    </div>
                  </Link>
                  <Link to={clinics_link} style={linkStyle}>
                    <div
                      className="menu-footer-style menu-align"
                      onClick={() => nav_function("clinics")}
                    >
                      {"CLINICS"}
                    </div>
                  </Link>
                </div>

                <div className="location-mobile-logo-container center-align">
                  <div className="inside-wrapper-content-2">
                    <div className="line"> </div>
                    <div className="logo-image-container">
                      {/*  <img
                        src={image_logo_white}
                        alt="image_logo_white"
                        className="image_logo_white"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const menu = () => {
    return (
      <div className="main-div-menu-container center-align">
        <div className="menu-container center-align">
          <div className="inside-container">
            <div
              className="menu-text-content font-color menu-align-1"
              onClick={() => nav_function("face")}
            >
              {"FACE"}
            </div>
            <div
              className="menu-text-content font-color"
              onClick={() => nav_function("body")}
            >
              {"BODY"}
            </div>
            <div
              className="menu-text-content font-color"
              onClick={() => nav_function("laser")}
            >
              {"LASER"}
            </div>
            <div
              className="menu-text-content font-color"
              onClick={() => nav_function("premium")}
            >
              {"PREMIUM"}
            </div>
            <div
              className="menu-text-content font-color"
              onClick={() => nav_function("reviews")}
            >
              {"REVIEWS"}
            </div>
            <div
              className="menu-text-content font-color"
              onClick={() => nav_function("home")}
            >
              {"ABOUT"}
            </div>
            <div
              className="menu-text-content font-color"
              onClick={() => nav_function("clinics")}
            >
              {"CLINICS"}
            </div>
            <div className="menu-text-content menu-align-2">
              <a href="https://www.facebook.com/skincell.care">
                <img
                  src={facebook_logo}
                  alt="image_hamburger_menu"
                  className="socmed_logo"
                />
              </a>
              <a href="https://www.instagram.com/skincell.care/">
                <img
                  src={instagram_logo}
                  alt="image_hamburger_menu"
                  className="socmed_logo socmed_logo_align"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="menu-container center-align">
          <div className="inside-container flex-end-align">
            <div onClick={() => nav_function("close_menu")}>
              <img
                src={image_hamburger_menu_blue}
                alt="image_hamburger_menu"
                className="image_hamburger_menu_orig"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={home_link} element={<Home />}></Route>
        <Route path={menu_link} element={<Menu />}></Route>
        <Route path={face_link} element={<Face />}></Route>
        <Route path={body_link} element={<Body />}></Route>
        <Route path={laser_link} element={<Laser />}></Route>
        <Route path={premium_link} element={<Premium />}></Route>
        <Route path={reviews_link} element={<Review />}></Route>
        <Route path={clinics_link} element={<Clinics />}></Route>
      </Routes>
    </BrowserRouter>
  );

  function Menu() {
    const nav_function_link = (link) => {
      return (
        <Link to={home_link} style={linkStyle}>
          <img
            src={image_hamburger_menu_blue}
            alt="image_hamburger_menu"
            className="image_hamburger_menu_orig"
          />
        </Link>
      );
    };

    return (
      <div className="main-div-menu-container center-align">
        <div className="menu-container center-align">
          <div className="inside-container">
            <Link to={face_link} style={linkStyle}>
              <div
                className="menu-text-content font-color menu-align-1"
                /*  onClick={() => nav_function("face")} */
              >
                {"FACE"}
              </div>
            </Link>
            <Link to={body_link} style={linkStyle}>
              <div
                className="menu-text-content font-color"
                /*    onClick={() => nav_function("body")} */
              >
                {"BODY"}
              </div>
            </Link>
            <Link to={laser_link} style={linkStyle}>
              <div
                className="menu-text-content font-color"
                /*   onClick={() => nav_function("laser")} */
              >
                {"LASER"}
              </div>
            </Link>
            <Link to={premium_link} style={linkStyle}>
              <div
                className="menu-text-content font-color"
                /*  onClick={() => nav_function("premium")} */
              >
                {"PREMIUM"}
              </div>
            </Link>
            <Link to={reviews_link} style={linkStyle}>
              <div
                className="menu-text-content font-color"
                /*  onClick={() => nav_function("reviews")} */
              >
                {"REVIEWS"}
              </div>
            </Link>
            <Link to={home_link} style={linkStyle}>
              <div
                className="menu-text-content font-color"
                /*   onClick={() => nav_function("home")} */
              >
                {"ABOUT"}
              </div>
            </Link>
            <Link to={clinics_link} style={linkStyle}>
              <div
                className="menu-text-content font-color"
                /*  onClick={() => nav_function("clinics")} */
              >
                {"CLINICS"}
              </div>
            </Link>
            <div className="menu-text-content menu-align-2">
              <a href="https://www.facebook.com/skincell.care">
                <img
                  src={facebook_logo}
                  alt="image_hamburger_menu"
                  className="socmed_logo"
                />
              </a>
              <a href="https://www.instagram.com/skincell.care/">
                <img
                  src={instagram_logo}
                  alt="image_hamburger_menu"
                  className="socmed_logo socmed_logo_align"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="menu-container center-align">
          <div className="inside-container flex-end-align">
            {/*  <div onClick={() => nav_function("close_menu")}> */}
            {nav_function_link()}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }

  function Home() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
              data-rh="true"
            />
            <meta name="googlebot" content="noarchive" data-rh="true" />
            <meta name="robots" content="noarchive" data-rh="true" />
            <meta
              http-equiv="x-ua-compatible"
              content="ie=edge"
              data-rh="true"
            />
            <title>SKINCELL | Advanced Aesthetic Clinics</title>

            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" data-rh="true" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics"
              data-rh="true"
            />
            <link
              rel="canonical"
              href="https://skincell.care/"
              data-rh="true"
            ></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout(
            video_cover_landing,
            "video",
            content_landing_page(),
            video_content_landing,
            "video",
            "home",
            mobile_cover_landing,
            mobile_content_image,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }

  function Face() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
            <meta name="googlebot" content="noarchive" />
            <meta name="robots" content="noarchive" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <title>SKINCELL | Advanced Aesthetic Clinics - FACE</title>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics - FACE"
            />
            <link rel="canonical" href="https://skincell.care/face"></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics - FACE"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout(
            face_content_landing,
            "image",
            content_face_page(),
            face_cover_desktop,
            "video",
            "face",
            face_content_mobile,
            face_cover_mobile,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }

  function Body() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
            <meta name="googlebot" content="noarchive" />
            <meta name="robots" content="noarchive" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <title>SKINCELL | Advanced Aesthetic Clinics - BODY</title>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics - BODY"
            />
            <link rel="canonical" href="https://skincell.care/body"></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics - BODY"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout(
            body_content_landing,
            "image",
            content_body_page(),
            body_cover_desktop,
            "video",
            "body",
            body_content_mobile,
            body_cover_mobile,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }

  function Laser() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
            <meta name="googlebot" content="noarchive" />
            <meta name="robots" content="noarchive" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <title>SKINCELL | Advanced Aesthetic Clinics - LASER</title>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics - LASER"
            />
            <link rel="canonical" href="https://skincell.care/laser"></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics - LASER"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout(
            laser_content_landing,
            "image",
            content_laser_page(),
            laser_cover_desktop,
            "video",
            "laser",
            laser_content_mobile,
            laser_cover_mobile,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }

  function Premium() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
            <meta name="googlebot" content="noarchive" />
            <meta name="robots" content="noarchive" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <title>SKINCELL | Advanced Aesthetic Clinics - PREMIUM</title>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics - PREMIUM"
            />
            <link rel="canonical" href="https://skincell.care/premium"></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics - PREMIUM"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout(
            premium_cover_desktop,
            "image",
            content_premium_page(),
            premium_content_desktop,
            "video",
            "premium",
            premium_cover_mobile,
            premium_content_mobile,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }

  function Review() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
            <meta name="googlebot" content="noarchive" />
            <meta name="robots" content="noarchive" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <title>SKINCELL | Advanced Aesthetic Clinics - REVIEW</title>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics - REVIEW"
            />
            <link rel="canonical" href="https://skincell.care/reviews"></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics - REVIEW"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout_2(
            review_cover_desktop,
            "image",
            content_review__page_two(),
            content_review_page(),
            "reviews",
            review_content_mobile,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }

  function Clinics() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
            <meta name="googlebot" content="noarchive" />
            <meta name="robots" content="noarchive" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <title>SKINCELL | Advanced Aesthetic Clinics - CLINIC</title>
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta
              property="og:title"
              content="SKINCELL | Advanced Aesthetic Clinics - CLINIC"
            />
            <link rel="canonical" href="https://skincell.care/clinics"></link>
            <meta
              name="twitter:title"
              content="SKINCELL | Advanced Aesthetic Clinics - CLINIC"
            ></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            />
            <meta
              name="twitter:description"
              content="SKINCELL is a dermatology, laser, and skin care clinic located in Manila, Makati, and BGC with mall branches at Venice Grand Canal Mall and Ayala Feliz."
            ></meta>

            <meta
              property="og:image"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta
              property="og:image:secure_url"
              content="https://skincell.care/img/skincell-white.JPG"
            />
            <meta
              name="twitter:image"
              content="http://skincell.care/img/skincell-white.JPG"
            ></meta>
            <meta
              name="google-site-verification"
              content="g4uC6cGaVe6nfIkKiAXu_eDThLSewwUXJxDMNRnVLS4"
            />
            <meta
              property="article:modified_time"
              content="2023-04-09T02:13:58+00:00"
            />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpg" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="http://skincell.care/img/skincell-white.JPG"
            />
            <meta http-equiv="Cache-control" content="public" />
          </Helmet>
          {layout(
            clinic_cover_desktop,
            "image",
            content_clinics_page(),
            clinic_content_landing,
            "image",
            "review",
            clinic_cover_mobile,
            clinic_content_mobile,
            menu_link
          )}
        </div>
      </HelmetProvider>
    );
  }
}

export default App;
